import { Box, Collapse, IconButton, Tooltip, useTheme } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { EnumListLockersMode, EnumMessageType, EnumPocketTab, EnumPocketTargetType, EnumSvgVariant } from "../enums";
import { PocketIcon } from "./svg_icons/PocketIcon";
import {
    useGetDirectories as useGetPocketDirectories,
    removeDirectoriesAction as removeDirectoriesFromPocketAction,
    setExpandedAction,
    useGetDocuments as useGetPocketDocuments,
    useGetNotes as useGetPocketNotes,
    removeDocumentsAction as removeDocumentsFromPocketAction,
    removeNotesAction as removeNotesFromPocketAction,
    useGetFotos as useGetPocketFotos,
    removeFotosAction as removeFotosFromPocketAction,
    useGetVideos as useGetPocketVideos,
    removeVideosAction as removeVideosFromPocketAction,
    useGetAudios as useGetPocketAudios,
    removeAudiosAction as removeAudiosFromPocketAction,
} from "../redux/features/pocket/pocketSlice";
import { DirectoryItem } from "./directories/DirectoryItem";
import { useMutation } from "@apollo/client";
import { GQL_MUTATION_REMOVE_FROM_POCKET, GQL_MUTATION_MOVE_TO_LOCKER, GQL_MUTATION_MOVE_TO_DIRECTORY, GQL_MUTATION_COPY_TO_LOCKER, GQL_MUTATION_COPY_TO_DIRECTORY, GQL_MUTATION_COPY_TO_ALBUM, GQL_MUTATION_MOVE_TO_ALBUM } from "../graphql/Mutations";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { addMessage } from "./messages/Message";
import { useDispatch, useSelector } from "react-redux";
import { IRootState, setAllRefetchNeeded } from "../redux/store";
import { DocumentItem } from "./documents/DocumentItem";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { SearchTool } from "./SearchTool";
import { FloatingSpinner } from "./spinner/FloatingSpinner";
import { NoteItem } from "./notes/NoteItem";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ModalQuickDrop } from "./quickDrop/ModalQuickDrop";
import { FotoItem } from "./fotos/FotoItem";
import { VideoItem } from "./fotos/VideoItem";
import { AudioItem } from "./fotos/AudioItem";
import BoltIcon from '@mui/icons-material/Bolt';

interface IProps {
    sx?: object,
    targetType?: EnumPocketTargetType,
    targetId?: string,
    targetMode?: EnumListLockersMode,
}

export const Pocket: FC<IProps> = (props) => {
    const expanded: boolean = useSelector((state: IRootState) => state.pocketReducer.expanded)
    const pocketTab: EnumPocketTab = useSelector((state: IRootState) => state.pocketReducer.tab)
    const pocketDirectories = useGetPocketDirectories()
    const pocketDocuments = useGetPocketDocuments()
    const pocketNotes = useGetPocketNotes()
    const pocketFotos = useGetPocketFotos()
    const pocketVideos = useGetPocketVideos()
    const pocketAudios = useGetPocketAudios()
    const theme = useTheme()
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)
    const [moveToLocker, { data: moveToLockerData, loading: moveToLockerLoading, error: moveToLockerError }] = useMutation(GQL_MUTATION_MOVE_TO_LOCKER)
    const [moveToDirectory, { data: moveToDirectoryData, loading: moveToDirectoryLoading, error: moveToDirectoryError }] = useMutation(GQL_MUTATION_MOVE_TO_DIRECTORY)
    const [moveToAlbum, { data: moveToAlbumData, loading: moveToAlbumLoading, error: moveToAlbumError }] = useMutation(GQL_MUTATION_MOVE_TO_ALBUM)
    const [copyToLocker, { data: copyToLockerData, loading: copyToLockerLoading, error: copyToLockerError }] = useMutation(GQL_MUTATION_COPY_TO_LOCKER)
    const [copyToDirectory, { data: copyToDirectoryData, loading: copyToDirectoryLoading, error: copyToDirectoryError }] = useMutation(GQL_MUTATION_COPY_TO_DIRECTORY)
    const [copyToAlbum, { data: copyToAlbumData, loading: copyToAlbumLoading, error: copyToAlbumError }] = useMutation(GQL_MUTATION_COPY_TO_ALBUM)
    const dispatch = useDispatch()

    const [showModalQuickDrop, setShowModalQuickDrop] = useState(false)

    const closeModalQuickDrop = () => {
        setShowModalQuickDrop(false)
    }

    // Gestion de click exterieur:
    // const pocketRef = useRef<HTMLDivElement>(null);
    // useEffect(() => {
    //     document.addEventListener("click", handleExternalPocketClick);
    //     return () => {
    //         document.removeEventListener("click", handleExternalPocketClick);
    //     };
    // }, []);

    // const handleExternalPocketClick = (event: MouseEvent) => {
    //     if (pocketRef.current && !pocketRef.current.contains(event.target as Node)) {
    //         // setExpanded(false);
    //         dispatch(setExpandedAction(false))
    //     }
    // }
    // Fin de gestion du click exterieur.

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                if (pocketDirectories || pocketDocuments || pocketNotes || pocketFotos || pocketVideos || pocketAudios) {
                    if (pocketDirectories) {
                        dispatch(removeDirectoriesFromPocketAction(pocketDirectories.map(pocketDirectory => pocketDirectory.id)))
                    }
                    if (pocketDocuments) {
                        dispatch(removeDocumentsFromPocketAction(pocketDocuments.map(pocketDocument => pocketDocument.id)))
                    }
                    if (pocketNotes) {
                        dispatch(removeNotesFromPocketAction(pocketNotes.map(pocketNote => pocketNote.id)))
                    }
                    if (pocketFotos) {
                        dispatch(removeFotosFromPocketAction(pocketFotos.map(pocketFoto => pocketFoto.id)))
                    }
                    if (pocketVideos) {
                        dispatch(removeVideosFromPocketAction(pocketVideos.map(pocketVideo => pocketVideo.id)))
                    }
                    if (pocketAudios) {
                        dispatch(removeAudiosFromPocketAction(pocketAudios.map(pocketAudio => pocketAudio.id)))
                    }
                    addMessage({
                        type: EnumMessageType.Success,
                        message: "Retiré de la pocket.",
                    })
                    // setExpanded(false)
                    dispatch(setExpandedAction(false))
                }
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    useEffect(() => {
        if (moveToLockerError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: moveToLockerError.message,
            })
        } else if (moveToLockerData) {
            if (moveToLockerData.moveToLocker.statusCode === 200) {
                if (pocketDirectories) {
                    setAllRefetchNeeded("Pocket")
                    addMessage({
                        type: EnumMessageType.Success,
                        message: "Déplacement effectué.",
                    })
                }
            } else {
                moveToLockerData.moveToLocker.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [moveToLockerData, moveToLockerError])

    useEffect(() => {
        if (moveToDirectoryError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: moveToDirectoryError.message,
            })
        } else if (moveToDirectoryData) {
            if (moveToDirectoryData.moveToDirectory.statusCode === 200) {
                if (pocketDirectories || pocketDocuments || pocketNotes) {
                    setAllRefetchNeeded("Pocket")
                    addMessage({
                        type: EnumMessageType.Success,
                        message: "Déplacement effectué.",
                    })

                }
            } else {
                moveToDirectoryData.moveToDirectory.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [moveToDirectoryData, moveToDirectoryError])

    useEffect(() => {
        if (moveToAlbumError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: moveToAlbumError.message,
            })
        } else if (moveToAlbumData) {
            if (moveToAlbumData.moveToAlbum.statusCode === 200) {
                if (pocketFotos || pocketVideos || pocketAudios) {
                    setAllRefetchNeeded("Pocket")
                    addMessage({
                        type: EnumMessageType.Success,
                        message: "Déplacement effectué.",
                    })

                }
            } else {
                moveToAlbumData.moveToAlbum.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [moveToAlbumData, moveToAlbumError])

    useEffect(() => {
        if (copyToLockerError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: copyToLockerError.message,
            })
        } else if (copyToLockerData) {
            if (copyToLockerData.copyToLocker.statusCode === 200) {
                if (pocketDirectories) {
                    setAllRefetchNeeded("Pocket")
                    addMessage({
                        type: EnumMessageType.Success,
                        message: "Copie effectuée.",
                    })
                }
            } else {
                copyToLockerData.copyToLocker.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [copyToLockerData, copyToLockerError])

    useEffect(() => {
        if (copyToDirectoryError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: copyToDirectoryError.message,
            })
        } else if (copyToDirectoryData) {
            if (copyToDirectoryData.copyToDirectory.statusCode === 200) {
                if (pocketDirectories || pocketDocuments || pocketNotes) {
                    setAllRefetchNeeded("Pocket")
                    addMessage({
                        type: EnumMessageType.Success,
                        message: "Copie effectuée.",
                    })
                }
            } else {
                copyToDirectoryData.copyToDirectory.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [copyToDirectoryData, copyToDirectoryError])

    useEffect(() => {
        if (copyToAlbumError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: copyToAlbumError.message,
            })
        } else if (copyToAlbumData) {
            if (copyToAlbumData.copyToAlbum.statusCode === 200) {
                if (pocketFotos || pocketVideos || pocketAudios) {
                    setAllRefetchNeeded("Pocket")
                    addMessage({
                        type: EnumMessageType.Success,
                        message: "Copie effectuée.",
                    })
                }
            } else {
                copyToAlbumData.copyToAlbum.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [copyToAlbumData, copyToAlbumError])

    const getPocketSize = () => {
        let res = 0
        if (pocketDirectories) {
            res += pocketDirectories.length
        }
        if (pocketDocuments) {
            res += pocketDocuments.length
        }
        if (pocketNotes) {
            res += pocketNotes.length
        }
        if (pocketFotos) {
            res += pocketFotos.length
        }
        if (pocketVideos) {
            res += pocketVideos.length
        }
        if (pocketAudios) {
            res += pocketAudios.length
        }
        return res
    }

    const handleExpandPocket = () => {
        const payload = {
            expanded: pocketTab !== EnumPocketTab.pocket ? true : !expanded,
            tab: EnumPocketTab.pocket,
        }
        dispatch(setExpandedAction(payload))
    }

    const handleExpandSearch = () => {
        const payload = {
            expanded: pocketTab !== EnumPocketTab.search ? true : !expanded,
            tab: EnumPocketTab.search,
        }
        dispatch(setExpandedAction(payload))
    }

    const getExpandedIconButtonDesign = () => {
        return expanded ? {
            bottom: "312px",
        } : {}
    }

    const getExpandedContentDesign = () => {
        return expanded ? {
            height: "300px", minHeight: "1px", zIndex: 2,
            bottom: "32px",
            transition: "height .5s ease, z-index 1s", // transition à la montée
            transitionDelay: "0s, 0s",
        } : {}
    }

    const handleRemoveFromPocket = () => {
        if (getPocketSize() > 0) {
            removeFromPocket({
                variables: {
                    directoryIds: pocketDirectories?.map(pocketDirectory => pocketDirectory.id),
                    documentIds: pocketDocuments?.map(pocketDocument => pocketDocument.id),
                    noteIds: pocketNotes?.map(pocketNote => pocketNote.id),
                    fotoIds: pocketFotos?.map(pocketFoto => pocketFoto.id),
                    videoIds: pocketVideos?.map(pocketVideo => pocketVideo.id),
                    audioIds: pocketAudios?.map(pocketAudio => pocketAudio.id),
                }
            })
        }
    }

    const handleMove = () => {
        if (!props.targetType || !props.targetId) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Destination incorecte.",
            })
        } else {
            if (props.targetType === EnumPocketTargetType.locker && getPocketSize() > 0) {
                moveToLocker({
                    variables: {
                        lockerId: props.targetId,
                        directoryIds: pocketDirectories?.map(pocketDirectory => pocketDirectory.id),
                    }
                })
            } else if (props.targetType === EnumPocketTargetType.directory && getPocketSize() > 0) {
                moveToDirectory({
                    variables: {
                        directoryId: props.targetId,
                        directoryIds: pocketDirectories?.map(pocketDirectory => pocketDirectory.id),
                        documentIds: pocketDocuments?.map(pocketDocument => pocketDocument.id),
                        noteIds: pocketNotes?.map(pocketNote => pocketNote.id),
                    }
                })
            } else if (props.targetType === EnumPocketTargetType.album && getPocketSize() > 0) {
                moveToAlbum({
                    variables: {
                        albumId: props.targetId,
                        fotoIds: pocketFotos?.map(pocketFoto => pocketFoto.id),
                        videoIds: pocketVideos?.map(pocketVideo => pocketVideo.id),
                        audioIds: pocketAudios?.map(pocketAudio => pocketAudio.id),
                    }
                })
            }
        }
    }

    const handleCopy = () => {
        if (!props.targetType || !props.targetId) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Destination incorecte.",
            })
        } else {
            if (props.targetType === EnumPocketTargetType.locker && getPocketSize() > 0) {
                copyToLocker({
                    variables: {
                        lockerId: props.targetId,
                        directoryIds: pocketDirectories?.map(pocketDirectory => pocketDirectory.id),
                    }
                })

            } else if (props.targetType === EnumPocketTargetType.directory && getPocketSize() > 0) {
                copyToDirectory({
                    variables: {
                        directoryId: props.targetId,
                        directoryIds: pocketDirectories?.map(pocketDirectory => pocketDirectory.id),
                        documentIds: pocketDocuments?.map(pocketDocument => pocketDocument.id),
                        noteIds: pocketNotes?.map(pocketNote => pocketNote.id),
                    }
                })
            } else if (props.targetType === EnumPocketTargetType.album && getPocketSize() > 0) {
                copyToAlbum({
                    variables: {
                        albumId: props.targetId,
                        fotoIds: pocketFotos?.map(pocketFoto => pocketFoto.id),
                        videoIds: pocketVideos?.map(pocketVideo => pocketVideo.id),
                        audioIds: pocketAudios?.map(pocketAudio => pocketAudio.id),
                    }
                })
            }
        }
    }

    return (
        <>
            <Box className="Pocket" sx={props.sx}>
                <FloatingSpinner dependances={[copyToDirectoryLoading, copyToLockerLoading, copyToAlbumLoading]} />
                <Box>

                    <IconButton onClick={handleExpandSearch}
                        sx={{
                            position: "fixed",
                            bottom: "12px",
                            // right: "55px",
                            right: "94px",
                            backgroundColor: (pocketTab === EnumPocketTab.search && expanded) ? "white" : theme.palette.background.default,
                            borderTop: "2px solid",
                            borderColor: theme.palette.primary.main,
                            mt: -2,
                            ":hover": {
                                backgroundColor: "white",
                            },
                            zIndex: 3,
                            transition: "bottom .5s ease",
                            ...getExpandedIconButtonDesign(),
                        }}
                    >
                        <SearchOutlinedIcon color="primary" sx={{ fontSize: 18 }} />
                    </IconButton>
                    <IconButton onClick={handleExpandPocket}
                        sx={{
                            position: "fixed",
                            bottom: "12px",
                            // right: "16px",
                            right: "55px",
                            backgroundColor: (pocketTab === EnumPocketTab.pocket && expanded) ? "white" : theme.palette.background.default,
                            borderTop: "2px solid",
                            borderColor: theme.palette.primary.main,
                            mt: -2,
                            ":hover": {
                                backgroundColor: "white",
                            },
                            zIndex: 3,
                            transition: "bottom .5s ease",
                            ...getExpandedIconButtonDesign(),
                        }}
                    >
                        {
                            getPocketSize() > 0
                                ?
                                <PocketIcon variant={EnumSvgVariant.secondary} color="secondary" sx={{ fontSize: 18 }} />
                                :
                                <PocketIcon variant={EnumSvgVariant.primary} sx={{ fontSize: 18 }} />
                        }
                    </IconButton>
                    <IconButton onClick={() => setShowModalQuickDrop(true)}
                        sx={{
                            position: "fixed",
                            bottom: "12px",
                            // right: "94px",
                            right: "16px",
                            backgroundColor: theme.palette.background.default,
                            borderTop: "2px solid",
                            borderColor: theme.palette.primary.main,
                            mt: -2,
                            ":hover": {
                                backgroundColor: "white",
                            },
                            zIndex: 3,
                            transition: "bottom .5s ease",
                            ...getExpandedIconButtonDesign(),
                        }}
                    >
                        {/* <CloudUploadOutlinedIcon color="primary" sx={{ fontSize: 18 }} /> */}
                        <BoltIcon color="secondary" sx={{ fontSize: 18 }} />
                    </IconButton>
                    <Collapse in={true || expanded} timeout="auto"
                        sx={{
                            position: "fixed",
                            bottom: "2px",
                            right: "4px",
                            zIndex: 1,
                            overflow: "hidden",
                            width: "250px",
                            borderTop: "1px solid",
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                            borderColor: theme.palette.primary.main,
                            borderTopLeftRadius: 7,
                            borderTopRightRadius: 7,
                            backgroundColor: theme.palette.background.default,
                            maxHeight: "300px",
                            height: "0",
                            transition: "height .5s ease, z-index 0s, bottom 1s ease", // transition à la descente
                            transitionDelay: "0s, 1s, 1s",
                            ...getExpandedContentDesign(),
                        }}
                    >
                        {
                            pocketTab === EnumPocketTab.pocket &&
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "300px",

                            }}>
                                <Box sx={{
                                    pl: 1,
                                    py: 2,
                                    width: "100%",
                                    overflowY: `${getPocketSize() > 3 ? "scroll" : "hidden"}`,
                                    overflowX: "hidden",
                                }}>
                                    {
                                        pocketDirectories &&
                                        pocketDirectories.map(pocketDirectory => <DirectoryItem key={pocketDirectory.id} directory={pocketDirectory} targetType={props.targetType} targetId={props.targetId} targetMode={props.targetMode} />)
                                    }
                                    {
                                        pocketDocuments &&
                                        pocketDocuments.map(pocketDocument => <DocumentItem key={pocketDocument.id} document={pocketDocument} targetType={props.targetType} targetId={props.targetId} targetMode={props.targetMode} />)
                                    }
                                    {
                                        pocketNotes &&
                                        pocketNotes.map(pocketNote => <NoteItem key={pocketNote.id} note={pocketNote} targetType={props.targetType} targetId={props.targetId} targetMode={props.targetMode} />)
                                    }
                                    {
                                        pocketFotos &&
                                        pocketFotos.map(pocketFoto => <FotoItem key={pocketFoto.id} foto={pocketFoto} targetType={props.targetType} targetId={props.targetId} targetMode={props.targetMode} />)
                                    }
                                    {
                                        pocketVideos &&
                                        pocketVideos.map(pocketVideo => <VideoItem key={pocketVideo.id} video={pocketVideo} targetType={props.targetType} targetId={props.targetId} targetMode={props.targetMode} />)
                                    }
                                    {
                                        pocketAudios &&
                                        pocketAudios.map(pocketAudio => <AudioItem key={pocketAudio.id} audio={pocketAudio} targetType={props.targetType} targetId={props.targetId} targetMode={props.targetMode} />)
                                    }
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    width: "100%",
                                    px: 1,
                                    py: 1,
                                }}>
                                    {
                                        props.targetType &&
                                        props.targetMode &&
                                        props.targetMode !== EnumListLockersMode.shared &&
                                        ([EnumPocketTargetType.locker, EnumPocketTargetType.directory, EnumPocketTargetType.album].includes(props.targetType)) &&
                                        <>
                                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Tout déplacer ici">
                                                <IconButton onClick={handleMove} color="primary" size="small">
                                                    <DriveFileMoveOutlinedIcon sx={{ fontSize: 16 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Tout copier ici">
                                                <IconButton onClick={handleCopy} color="primary" size="small">
                                                    <FileCopyOutlinedIcon sx={{ fontSize: 16 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    }
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Tout retirer de la pocket">
                                        <IconButton onClick={handleRemoveFromPocket} color="primary" size="small">
                                            <PocketIcon variant={EnumSvgVariant.primary} sx={{ fontSize: 16 }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        }
                        {
                            pocketTab === EnumPocketTab.search &&
                            <Box sx={{
                                // display: "flex",
                                // flexDirection: "column",
                                // justifyContent: "space-between",
                                // alignItems: "center",
                                px: 1,
                                py: 0,
                                height: "300px",
                                overflowY: "scroll",

                            }}>
                                <SearchTool />
                            </Box>
                        }
                    </Collapse>
                </Box>
            </Box>
            <ModalQuickDrop
                open={showModalQuickDrop}
                handleClose={closeModalQuickDrop}
            />
        </>
    )
}





