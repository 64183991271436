import { Box, Button, Collapse, Divider, Paper, Typography, useTheme, Chip, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { IBankAccount } from "../../interfaces";
import { DeleteOutline, EditOutlined } from '@mui/icons-material';

import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { useMutation } from "@apollo/client";
import { addMessage } from "../messages/Message";
import { EnumAccountOperationType, EnumBankAccountType, EnumMessageType, EnumMetadocLogoMode, EnumSliceReference } from "../../enums";
import {
    GQL_MUTATION_ADD_IN_BANK_ACCOUNTS_SHORTCUTTED,
    GQL_MUTATION_DELETE_BANK_ACCOUNT,
    GQL_MUTATION_REMOVE_FROM_BANK_ACCOUNTS_SHORTCUTTED
} from "../../graphql/Mutations";
import { ModalEditBankAccount } from "./modals/ModalEditBankAccount";
import { useNavigate } from "react-router-dom";
import { NAVIGATE_CASHFLOW } from "../../utils/Navigation";
import { setAllRefetchNeeded } from "../../redux/store";
import { Flexbox } from "../utils/Flexbox";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { BankAccountIcon } from '../svg_icons/BankAccountIcon';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice';
import { formatFancyCurrency } from '../../utils/Utils';
import SyncIcon from '@mui/icons-material/Sync';
import { ModalSyncBankAccountWithBank } from './modals/ModalSyncBankAccountWithBank';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { MetadocLogo } from '../MetadocLogo';

interface IProps {
    bankAccount: IBankAccount,
}

export const BankAccountDetails: FC<IProps> = (props) => {
    const [showDeleteBankAccountDialog, setShowDeleteBankAccountDialog] = useState(false)
    const [showModalEditBankAccount, setShowModalEditBankAccount] = useState(false)
    const [showModalSyncBankAccountWithBank, setShowModalSyncBankAccountWithBank] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const currentUser = useGetCurrentUser()

    const [deleteBankAccount, { data: deleteBankAccountData, loading: deleteBankAccountLoading, error: deleteBankAccountError }] = useMutation(GQL_MUTATION_DELETE_BANK_ACCOUNT)
    const [addInBankAccountsShortcutted, { data: addInBankAccountsShortcuttedData, loading: addInBankAccountsShortcuttedLoading, error: addInBankAccountsShortcuttedError }] = useMutation(GQL_MUTATION_ADD_IN_BANK_ACCOUNTS_SHORTCUTTED)
    const [removeFromBankAccountsShortcutted, { data: removeFromBankAccountsShortcuttedData, loading: removeFromBankAccountsShortcuttedLoading, error: removeFromBankAccountsShortcuttedError }] = useMutation(GQL_MUTATION_REMOVE_FROM_BANK_ACCOUNTS_SHORTCUTTED)

    const navigate = useNavigate()
    const theme = useTheme()

    useEffect(() => {
        if (deleteBankAccountError) {
            addMessage({
                location: "BankAccount",
                type: EnumMessageType.Error,
                message: deleteBankAccountError.message,
            })
        } else if (deleteBankAccountData) {
            if (deleteBankAccountData.deleteBankAccount.statusCode === 200) {
                setAllRefetchNeeded("BankAccountDetails", [EnumSliceReference.bankAccount])
                navigate(NAVIGATE_CASHFLOW)
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Compte supprimé.",
                })
            } else {
                deleteBankAccountData.deleteBankAccount.errors.map((error: string) => {
                    addMessage({
                        location: "BankAccountDetails",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteBankAccountData, deleteBankAccountError])

    useEffect(() => {
        if (addInBankAccountsShortcuttedError) {
            addMessage({
                location: "BankAccountDetails",
                type: EnumMessageType.Error,
                message: addInBankAccountsShortcuttedError.message,
            })
        } else if (addInBankAccountsShortcuttedData) {
            if (addInBankAccountsShortcuttedData.addInBankAccountsShortcutted.statusCode === 200) {
                setAllRefetchNeeded("BankAccountDetails")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Opérations rapides activées.",
                })
            } else {
                addInBankAccountsShortcuttedData.addInBankAccountsShortcutted.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInBankAccountsShortcuttedData, addInBankAccountsShortcuttedError])

    useEffect(() => {
        if (removeFromBankAccountsShortcuttedError) {
            addMessage({
                location: "BankAccountDetails",
                type: EnumMessageType.Error,
                message: removeFromBankAccountsShortcuttedError.message,
            })
        } else if (removeFromBankAccountsShortcuttedData) {
            if (removeFromBankAccountsShortcuttedData.removeFromBankAccountsShortcutted.statusCode === 200) {
                setAllRefetchNeeded("BankAccountDetails")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Opérations rapides désactivées.",
                })
            } else {
                removeFromBankAccountsShortcuttedData.removeFromBankAccountsShortcutted.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromBankAccountsShortcuttedData, removeFromBankAccountsShortcuttedError])


    const closeModalEditBankAccount = () => {
        setShowModalEditBankAccount(false)
    }

    const handleEdit = () => {
        setShowModalEditBankAccount(true)
    }

    const handleDelete = () => {
        setShowDeleteBankAccountDialog(true)
    }

    const closeDeleteBankAccountDialog = () => {
        setShowDeleteBankAccountDialog(false)
    }

    const confirmedDeleteBankAccount = () => {
        deleteBankAccount({
            variables: {
                bankAccountId: props.bankAccount.id
            }
        })
        setShowDeleteBankAccountDialog(false)
    }

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    const isInBankAccountsShortcutted = () => {
        if (currentUser) {
            return currentUser.bankAccountsShortcutted.filter(bankAccount => bankAccount.id === props.bankAccount.id).length > 0
        }
        return false
    }

    const handlePutInBankAccountsShortcutted = () => {
        addInBankAccountsShortcutted({
            variables: {
                bankAccountId: props.bankAccount.id,
            }
        })
    }

    const handleRemoveFromBankAccountsShortcutted = () => {
        removeFromBankAccountsShortcutted({
            variables: {
                bankAccountId: props.bankAccount.id,
            }
        })
    }

    const handleSyncBank = () => {
        setShowModalSyncBankAccountWithBank(true)
    }

    const closeModalSyncBankAccountWithBank = () => {
        setShowModalSyncBankAccountWithBank(false)
    }

    return (
        <>
            <FloatingSpinner dependances={[deleteBankAccountLoading]} />
            <Paper sx={{
                marginTop: 3,
                marginBottom: 1.5,
                marginLeft: 1.5,
                marginRight: 1.5,
                width: 'calc(100% - 24px)',
                transition: 'initial !important',
            }}>
                <Box className="BankAccountDetails-container" sx={{
                    paddingTop: 1.5,
                    paddingBottom: .75,
                    paddingLeft: .75,
                    paddingRight: .75,
                }}>
                    <Box className="BankAccountDetails-main-section" sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}>
                        <Box className="BankAccountDetails-icon-section" sx={{
                            paddingTop: 2,
                            paddingBottom: 1,
                            paddingLeft: 1,
                            paddingRight: 1,
                            marginTop: -4,
                            marginLeft: -3,
                            backgroundColor: "white",
                            borderRadius: '50%',
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                        }}>
                            <BankAccountIcon color={props.bankAccount.cbColor} sx={{ fontSize: 24, mx: 0.5, mt: -0.6 }} />
                        </Box>
                        <Box className="BankAccountDetails-content-section" sx={{
                            width: '100%',
                            paddingLeft: .5,
                            paddingRight: .5,
                            paddingBottom: .5,
                        }}>
                            <Box className="BankAccountDetails-title-section" sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                            }}>
                                <Typography color="text.primary" sx={{ fontSize: 24 }}>
                                    {props.bankAccount.title}
                                </Typography>
                                <Box className="BankAccountDetails-expand-section">
                                    <ExpandMore expand={expanded}
                                        onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="BankAccountDetails-expanded-section" sx={{
                        pl: 3.75,
                    }}>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {
                                props.bankAccount.description && <>
                                    <Box className="BankAccountDetails-description-section" sx={{
                                        mt: 1.5,
                                    }}>
                                        <Typography sx={{ fontSize: "16px", my: 3 }}>
                                            {props.bankAccount.description}
                                        </Typography>
                                    </Box>
                                </>
                            }
                            <Grid container className="BankAccountDetails-infos-section" sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                flexWrap: 'wrap',
                                marginTop: 1.5,
                            }}>
                                <Grid size={{ xs: 12, md: 3 }} className="BankAccountDetails-infos-content" sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    mb: 2,
                                }}>
                                    <Divider flexItem sx={{ mb: 1 }}>Informations</Divider>
                                    <Typography
                                        sx={{
                                            color: theme.palette.secondary.main,
                                            fontSize: 18,
                                        }}
                                    >
                                        {props.bankAccount.bankName}
                                    </Typography>
                                    {
                                        props.bankAccount.bankAccountType === EnumBankAccountType.business && <>
                                            <Flexbox>
                                                <Chip size='small' label="Pro" color="desktop" sx={{ mr: 1 }} />
                                                <Typography>
                                                    Compte professionnel
                                                </Typography>
                                            </Flexbox>
                                            <Typography>
                                                Taux d'imposition : {props.bankAccount.taxRate} %
                                            </Typography>
                                        </>
                                    }
                                    {
                                        props.bankAccount.bankAccountType === EnumBankAccountType.standard && <>
                                            <Typography>
                                                Compte personnel
                                            </Typography>
                                        </>
                                    }

                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }} className="BankAccountDetails-infos-resultats" sx={{ mb: 2 }}>
                                    <Box>
                                        <Divider flexItem>Solde du compte</Divider>
                                        <Flexbox direction='column' sx={{
                                            alignItems: "flex-start",
                                            mt: 1,
                                            gap: 1,
                                        }} >
                                            <Flexbox>
                                                <MetadocLogo mode={EnumMetadocLogoMode.tiny} link={false} sx={{
                                                    mx: 1,
                                                    width: 24,
                                                }} />
                                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Solde actuel">
                                                    <Typography sx={{
                                                        fontSize: 18,
                                                        color: parseFloat(props.bankAccount.realBalance) >= 0 ? theme.palette.credit.main : theme.palette.debit.main,
                                                    }}>
                                                        {formatFancyCurrency(parseFloat(props.bankAccount.realBalance), parseFloat(props.bankAccount.realBalance) >= 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit)}
                                                    </Typography>
                                                </Tooltip>
                                            </Flexbox>
                                            <Flexbox sx={{
                                                justifyContent: "space-between",
                                                width: "100%",
                                            }}>
                                                <Flexbox>
                                                    <AccountBalanceIcon sx={{
                                                        color: theme.palette.primary.main,
                                                        mx: 1,
                                                    }} />
                                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Solde bancaire">
                                                        <Typography sx={{
                                                            fontSize: 18,
                                                            color: parseFloat(props.bankAccount.bankBalance) >= 0 ? theme.palette.credit.main : theme.palette.debit.main,
                                                        }}>
                                                            {formatFancyCurrency(parseFloat(props.bankAccount.bankBalance), parseFloat(props.bankAccount.bankBalance) >= 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit)}
                                                        </Typography>
                                                    </Tooltip>
                                                </Flexbox>
                                                <Button startIcon={<SyncIcon />} color="primary" size="small" onClick={handleSyncBank}>Synchroniser</Button>
                                            </Flexbox>
                                        </Flexbox>
                                    </Box>
                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }} className="BankAccountDetails-actions"
                                    sx={{ mb: 2 }}
                                >
                                    <Divider flexItem sx={{ mb: 1 }}>Actions</Divider>
                                    <Flexbox sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <Flexbox direction="column" sx={{ alignItems: "flex-start" }} >
                                            <Button startIcon={<EditOutlined />} color="primary" size="small" onClick={handleEdit}>Modifier</Button>
                                        </Flexbox>
                                        <Flexbox direction="column" sx={{ alignItems: "flex-start" }}>
                                            {
                                                isInBankAccountsShortcutted()
                                                    ?
                                                    <Button startIcon={<SwitchAccessShortcutIcon />} color="secondary" size="small" onClick={handleRemoveFromBankAccountsShortcutted}>Opérations rapides</Button>
                                                    :
                                                    <Button startIcon={<SwitchAccessShortcutIcon />} color="primary" size="small" onClick={handlePutInBankAccountsShortcutted}>Opérations rapides</Button>
                                            }
                                            <Button startIcon={<DeleteOutline />} color="error" size="small" onClick={handleDelete}>Supprimer</Button>
                                        </Flexbox >
                                    </Flexbox>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditBankAccount
                open={showModalEditBankAccount}
                handleClose={closeModalEditBankAccount}
                bankAccount={props.bankAccount}
            />
            <ModalDeleteConfirm
                open={showDeleteBankAccountDialog}
                title="Suppression du compte"
                content={`Confirmez-vous la suppression définitive du compte ${props.bankAccount.title} ?`}
                handleClose={closeDeleteBankAccountDialog}
                handleConfirm={confirmedDeleteBankAccount}
            />
            <ModalSyncBankAccountWithBank
                open={showModalSyncBankAccountWithBank}
                handleClose={closeModalSyncBankAccountWithBank}
                bankAccount={props.bankAccount}
            />
        </>
    )
}
